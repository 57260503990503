import { ApiCreateApplicationForm, ApiPeople } from '@@types/apiLogicTypes'
import FormInput from '@components/ui/Form/FormInput'
import FormMultiselect from '@components/ui/Form/FormMultiselect/FormMultiselect'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { UiText } from '@components/ui/UiText/UiText'
import { IDisabled } from '@modules/TicketSale/Form/Form'
import cn from 'classnames'
import moment, { isDate } from 'moment'
import { Calendar } from 'primereact/calendar'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

const ChildPart: FC<IDisabled> = ({ disable }) => {
  const methods = useFormContext<ApiCreateApplicationForm>()

  const [newKidsCount, setNewKidsCount] = useState(0)

  /**
   * A function that handles adding a kid.
   */
  const addKidHandler = () => {
    setNewKidsCount((prev) => prev + 1)
  }

  /**
   * A function that handles the removal of a kid.
   */
  const removeKidHandler = () => {
    setNewKidsCount((prev) => prev - 1)
    methods.resetField('order.newKid')
  }

  const selectedChildTemplate = (child: ApiPeople) => {
    if (!child) return
    // Дата рождения
    const birthDate = moment(child.birth, 'YYYY-MM-DD')

    // Текущая дата
    const currentDate = moment()

    // Количество полных лет
    const age = currentDate.diff(birthDate, 'years')

    return (
      <div className='p-multiselect-token'>
        <span className='p-multiselect-token-label'>{`${child.name} ${child.birth && `, ${age} лет`}`}</span>
      </div>
    )
  }

  const childTemplate = (child: ApiPeople) => {
    // Дата рождения
    const birthDate = moment(child.birth, 'YYYY-MM-DD')

    // Текущая дата
    const currentDate = moment()

    // Количество полных лет
    const age = currentDate.diff(birthDate, 'years')

    return <span>{`${child.name} ${child.birth && `, ${age} лет`}`}</span>
  }

  const calcAge = (index: number) => {
    const date = methods.watch(`order.newKid.${index}.birth`)
    if (isDate(date)) {
      const birthDate = moment(date, 'YYYY-MM-DD')
      const currentDate = moment()

      const age = currentDate.diff(birthDate, 'years')

      return <span className='text-sm whitespace-nowrap'>{age + ' лет'}</span>
    }

    return null
  }

  return (
    <div className='flex flex-col gap-4 w-full'>
      <div className='flex gap-4 items-center'>
        <UiText size='lg' weight='bold'>
          Ребенок
        </UiText>
        <UiButton onClick={addKidHandler} type='button' disabled={disable} color={disable ? 'grey' : 'green'}>
          <i className='pi pi-plus text-sm'></i>
        </UiButton>
      </div>
      <div className='flex gap-4'>
        <FormMultiselect
          name='order.children'
          placeholder='Выбрать ребенка'
          customSize='full'
          options={methods.watch('order.user.children')}
          disabled={disable || !methods.watch('order.user.children')}
          maxSelectedLabels={10}
          itemTemplate={childTemplate}
          selectedItemTemplate={selectedChildTemplate}
        />
        <UiButton type='button' disabled={true} className='!cursor-default' color='grey'>
          <i className='pi pi-pencil text-sm'></i>
        </UiButton>
      </div>
      {Array.from({ length: newKidsCount }, (_, index) => (
        <div className='flex gap-4 items-center' key={index}>
          <FormInput
            className='w-full'
            name={`order.newKid.${index}.name`}
            placeholder='Имя'
            required={true}
            containerClassName='w-full'
          />
          <Controller
            control={methods.control}
            name={`order.newKid.${index}.birth`}
            rules={{ required: 'Date is required.' }}
            render={({ field, fieldState }) => (
              <Calendar
                value={field.value}
                onChange={field.onChange}
                showIcon={true}
                dateFormat='dd.mm.yy'
                locale='ru'
                className={cn(`min-w-[135px] worker shadow-none`, fieldState.error && 'worker-error')}
                maxDate={new Date()}
                placeholder='ДД.ММ.ГГГГ'
                inputClassName='shadow-none'
                mask='99.99.9999'
              />
            )}
          />
          {calcAge(index)}
          <UiButton onClick={removeKidHandler} type='button' className='!bg-red'>
            <i className='pi pi-times text-sm'></i>
          </UiButton>
        </div>
      ))}
    </div>
  )
}

export default ChildPart
