import { ApiApplication } from '@@types/apiLogicTypes'
import { priceBodyTemplate } from '@components/ui/ColumnContents/ColumnContents'
import { UiButton } from '@components/ui/UiButton/UiButton'
import { AppRoutesPaths } from '@config/navigation'
import { useStore } from '@hooks/storeHook'
import { statusSwitcher } from '@utils/tableUtils'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { Calendar } from 'primereact/calendar'
import { Column, ColumnFilterElementTemplateOptions } from 'primereact/column'
import { DataTable, DataTableValue } from 'primereact/datatable'
import { Dropdown } from 'primereact/dropdown'
import { FC, useEffect, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

const statuses = [
  { id: 1, name: 'Новая' },
  { id: 2, name: 'Перезвонить' },
  { id: 3, name: 'Ждем оплаты' },
  { id: 4, name: 'Оплачена' },
  { id: 5, name: 'Предоплачена' },
  { id: 0, name: 'Не реализована' },
  { id: 100, name: 'Завершена' },
  { id: 1000, name: 'Удалена' },
]

export const Table: FC = observer(() => {
  const {
    applications: { applications },
  } = useStore()
  const navigate = useNavigate()
  const [filters, setFilters] = useState<any>(null)

  const holidayTemplate = (e: ApiApplication) => {
    return <span>{e.holiday ? e.holiday.name : '-'}</span>
  }

  useEffect(() => {
    initFilters()
  }, [])

  const initFilters = () => {
    setFilters({
      created_at: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
      'schedule.starts_at': {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
      },
      status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    })
  }

  const statusRowFilterTemplate = (options: ColumnFilterElementTemplateOptions) => {
    return (
      <Dropdown
        value={statuses.find((e) => e.id === options.value) ?? options.value}
        options={statuses}
        optionLabel='name'
        onChange={(e) => options.filterApplyCallback(e.value?.id ? e.value.id : e.value, options.index)}
        placeholder='Выберите статус'
        className='p-column-filter'
        showClear
        style={{ minWidth: '12rem' }}
      />
    )
  }

  const dateBodyTemplate = (rowData?: Date) => {
    if (!rowData) return '-'
    return moment(rowData).format('DD.MM.YYYY') ?? '-'
  }

  const childTemplate = (e: ApiApplication) => {
    return <span>{e.children?.map((e) => e.name).join(', ')}</span>
  }

  const onRowDoubleClickHandler = (e: DataTableValue) => {
    if (e.holiday) {
      const path = generatePath(AppRoutesPaths.ConstructorEdit, {
        id: String(e.id),
      })

      navigate(path)
    }
  }

  const dateFilterTemplate = (options: any, maxDate?: Date) => {
    const selectionMode = options.filterModel.matchMode === FilterMatchMode.BETWEEN ? 'range' : 'single'

    return (
      <Calendar
        selectionMode={selectionMode}
        value={options.value}
        onChange={(e: any) => {
          const selectedDates = e.value
          if (selectedDates?.[1]) {
            selectedDates[1] = new Date(selectedDates[1].setHours(23, 59, 59, 999))
          }
          options.filterCallback(selectedDates, options.index)
        }}
        dateFormat='dd.mm.yy'
        placeholder='Период'
        locale='ru'
        maxDate={maxDate}
        hideOnRangeSelection={selectionMode === 'range'}
        mask={selectionMode === 'range' ? '99.99.9999 - 99.99.9999' : '99.99.9999'}
      />
    )
  }

  const sourceTemplate = (e: ApiApplication) => {
    if (!e.source) return <></>

    return <span>{e.source}</span>
  }

  const utmTemplate = (e: ApiApplication) => {
    if (!e.utm) return <></>

    return <span>{e.utm}</span>
  }

  return (
    <main className='mt-4 h-full overflow-auto'>
      <DataTable
        value={applications.map((e) => ({
          ...e,
          created_at: new Date(e.created_at),
          schedule: e.schedule ? { ...e.schedule, starts_at: new Date(e.schedule.starts_at) } : undefined,
        }))}
        showGridlines
        size={'small'}
        filterLocale='ru'
        className='bg-white text-base ui-paginator-table'
        style={{ fontSize: '12px' }}
        removableSort
        emptyMessage='Заказы не найдены'
        scrollable
        scrollHeight='flex'
        rowClassName={(e) => {
          //@ts-ignore
          return statusSwitcher(e).color + `${e.schedule ? ' cursor-pointer' : ''}`
        }}
        filters={filters}
        paginator
        rows={20}
        rowsPerPageOptions={[20, 35, 50]}
        onRowDoubleClick={(e) => onRowDoubleClickHandler(e.data)}
        sortField='id'
        sortOrder={-1}
      >
        <Column
          field='created_at'
          filterField='created_at'
          header='Дата создания'
          dataType='date'
          body={(item: ApiApplication) => dateBodyTemplate(new Date(item.created_at))}
          filterMatchMode={FilterMatchMode.BETWEEN}
          showFilterMatchModes={true}
          filterMatchModeOptions={[
            { label: 'Период', value: 'between' },
            { label: 'За день', value: 'dateIs' },
          ]}
          showFilterMenuOptions={true}
          showAddButton={false}
          showFilterOperator={false}
          filter
          filterElement={(e) => dateFilterTemplate(e, new Date())}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton
              onClick={e.filterClearCallback}
              className='capitalize !border border-solid border-blue'
              color='blue'
            >
              Очистить
            </UiButton>
          )}
        />
        <Column field='id' header='Номер' sortable />
        <Column
          header='Дата мероприятия'
          filterField='schedule.starts_at'
          dataType='date'
          body={(item: ApiApplication) =>
            dateBodyTemplate(item.schedule ? new Date(item.schedule?.starts_at) : undefined)
          }
          filterMatchMode={FilterMatchMode.BETWEEN}
          showFilterMatchModes={true}
          filterMatchModeOptions={[
            { label: 'Период', value: 'between' },
            { label: 'За день', value: 'dateIs' },
          ]}
          showFilterMenuOptions={true}
          showAddButton={false}
          showFilterOperator={false}
          filter
          filterElement={dateFilterTemplate}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton onClick={e.filterClearCallback} className='capitalize !border border-solid border-blue bg-blue'>
              Очистить
            </UiButton>
          )}
        />
        <Column field='price' header='Сумма' body={(item: ApiApplication) => priceBodyTemplate(item.price)} sortable />
        <Column field='holidays' header='Праздник' body={holidayTemplate} />
        <Column field='user.name' header='Клиент' />
        <Column header='Источник' body={sourceTemplate} />
        <Column header='UTM' body={utmTemplate} />
        <Column
          header='Статус'
          field='status'
          dataType='numeric'
          filterField='status'
          body={(e) => <span>{statusSwitcher(e).name}</span>}
          filter
          filterElement={statusRowFilterTemplate}
          showFilterMatchModes={false}
          showFilterMenuOptions={false}
          filterApply={(e) => (
            <UiButton onClick={e.filterApplyCallback} className='capitalize'>
              Принять
            </UiButton>
          )}
          filterClear={(e) => (
            <UiButton
              onClick={e.filterClearCallback}
              className='capitalize !border border-solid border-blue'
              color='blue'
            >
              Очистить
            </UiButton>
          )}
        />
        <Column field='children' header='Дети' body={(e) => childTemplate(e)} />
      </DataTable>
    </main>
  )
})
