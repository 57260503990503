import { ApiApplication } from '@@types/apiLogicTypes'
import { UiText } from '@components/ui/UiText/UiText'
import { appColors, kanbanColors } from '@config/theme'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { formatDate, formatRelativeTime } from '@utils/formatDates'
import { FC } from 'react'

type KanbanTaskType = {
  application: ApiApplication
  disable: boolean
  className?: string
  color?: (typeof kanbanColors)[keyof typeof kanbanColors]
  onDoubleClick?: (event: ApiApplication) => void
}

const KanbanTask: FC<KanbanTaskType> = ({
  application,
  color = kanbanColors.grey,
  className,
  onDoubleClick,
  disable,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: application.id,
    data: {
      type: 'Application',
      application,
    },
    disabled: disable,
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    borderColor: isDragging ? color.primary : undefined,
  }
  return (
    <div
      className={`w-full flex flex-col items-center rounded-[3px] py-[16px] px-[8px] ${
        disable ? 'cursor-pointer' : 'cursor-grab'
      }  ${isDragging && 'border-2 border-solid'} ${className}`}
      style={{ ...style, backgroundColor: color.secondary }}
      ref={disable ? null : setNodeRef}
      {...listeners}
      {...attributes}
      onDoubleClick={() => onDoubleClick && onDoubleClick(application)}
    >
      <div className={`w-full flex flex-col gap-y-2 relative ${isDragging && 'opacity-0'}`}>
        <div className={`flex justify-between`}>
          <UiText size='sm' weight='bold'>
            {application.schedule &&
              formatDate(application.schedule.starts_at.substring(0, 10)) +
                ' - ' +
                application.schedule.starts_at.substring(11, 16) +
                ' - ' +
                application.schedule.ends_at.substring(11, 16)}
          </UiText>
          <div className='flex gap-x-1'>
            <UiText className='text-grey !text-xs'>{formatRelativeTime(application.created_at)}</UiText>
            <i
              className='pi pi-clock'
              style={{
                fontSize: '12px',
                color: appColors.grey,
                fontWeight: '700',
              }}
            ></i>
          </div>
        </div>
        <div className='flex justify-between'>
          {application.user && (
            <>
              <UiText
                size='sm'
                className='max-w-[60%] overflow-hidden text-ellipsis whitespace-nowrap cursor-help'
                isShowTooltip={true}
              >
                {application.user.name}
              </UiText>
              <UiText size='sm'>+7{application.user.phone}</UiText>
            </>
          )}
        </div>
        <div className='flex justify-between items-end'>
          <UiText
            size='sm'
            className='max-w-[40%] overflow-hidden text-ellipsis whitespace-nowrap cursor-help'
            isShowTooltip={true}
          >
            {application.holiday?.name}
          </UiText>
          {application.price > -1 && (
            <UiText size='sm' weight='bold'>
              {application.price} ₽
            </UiText>
          )}
          {application.status !== 1000 && application.status !== 0 && (
            <UiText
              weight='bold'
              className={`text-grey !text-xs flex items-center after:content-[""] after:w-[7px] after:h-[7px] after:block after:ml-1 after:rounded-[50%] ${
                statusSwitcher(application.status).classname
              }`}
            >
              {statusSwitcher(application.status).title}
            </UiText>
          )}
        </div>
      </div>
    </div>
  )
}

const statusSwitcher = (status: number) => {
  switch (status) {
    case 4: {
      return {
        title: 'Оплачен',
        classname: 'after:bg-green',
      }
    }
    case 100: {
      return {
        title: 'Оплачен',
        classname: 'after:bg-green',
      }
    }
    case 5: {
      return { title: 'Предоплачен', classname: 'after:bg-violet' }
    }
    default: {
      return { title: 'Не оплачен', classname: 'after:bg-orange' }
    }
  }
}

export default KanbanTask
